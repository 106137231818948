import { Card, Row, Col, Divider, Image } from 'antd';
const { Meta } = Card;

function Sports(props) {
  return (
    <>
    <div ref={props.Sports}>
    <Row style={{backgroundColor: "#ffbd59", paddingBottom: 30, color: "#ffbd59" }} justify="space-around">
    <Col sm={24}xs={24} style={{marginTop: 40}}>
    <span style={{display: 'flex', fontWeight: 'bolder', fontSize: "4vw", justifyContent:'center',alignItems:'center', color: 'white'}}>Professional Sports Training</span>
    </Col>
    <Col md={6} xs={24} sm={24} style={{marginTop: 40}}>
    <Card
    hoverable
    cover={<div style={{backgroundColor: 'white', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
      <Image
      height={300}
            src={require('../assets/sports-1.jpg')}
        />
      {/*  <img alt="example" src={require('../assets/sports-1.jpg')} /> */}
       </div>
       }
  >
    <Meta title="200m International Standard Skating Track" />
  </Card>
        </Col>
        <Col md={6} xs={24} sm={24} style={{marginTop: 40}}>
    <Card
    hoverable
    cover={<div style={{backgroundColor: 'white', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
    <Image
    height={300}
          src={require('../assets/sports-2.png')}
      /></div>}
  >
    <Meta title="Professional Tennis Mud Court" />
  </Card>
        </Col>
        <Col md={6} xs={24} sm={24} style={{marginTop: 40}}>
    <Card
    hoverable
    cover={<div style={{backgroundColor: 'white', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
      <Image
      height={300}
    src={require('../assets/sports-3.png')}
/></div>}
  >
    <Meta title="75m Standard Archery Range"  />
  </Card>
        </Col>
    </Row>

    <Divider style={{color: 'white', margin: 5}}/>

    <Row style={{backgroundColor: "#ffbd59",paddingBottom: 30 }} justify="space-around">
    <Col span={24} style={{marginTop: 40}}>
    <span style={{display: 'flex', fontWeight: 'bolder', fontSize: "4vw", justifyContent:'center',alignItems:'center', color: 'white'}}>Professional Sports Training</span>
    </Col>
    <Col md={6} xs={24} sm={24} style={{marginTop: 40}}>
      
    <Card
    hoverable
    cover={
      <div style={{backgroundColor: 'white', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
      <Image
      height={300}
    src={require('../assets/sports-4.png')}
/></div>}
  >
    <Meta title="Futsal Court" />
  </Card>
        </Col>
        <Col md={6} xs={24} sm={24} style={{marginTop: 40}}>
    <Card
    hoverable
    cover={<div style={{backgroundColor: 'white', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
    <Image
    height={300}
  src={require('../assets/sports-5.png')}
/></div>}
  >
    <Meta title="Taekwondo" />
  </Card>
        </Col>
        <Col md={6} xs={24} sm={24} style={{marginTop: 40}}>
    <Card
    hoverable
    cover={<div style={{backgroundColor: 'white', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
    <Image
    height={300}
  src={require('../assets/sports-6.png')}
/></div>}
  >
    <Meta title="Gymnastics" />
  </Card>
        </Col>
    </Row>
    </div>
    </>
  );
}

export default Sports;
