import { useRef } from "react";
import { Layout, Image, Row, Col, Menu } from 'antd';
import image from "../assets/banner.jpg"; 
import Sports from './Sports';
import Contact from './Contact';
const { Header, Content } = Layout;
    
function Home() {
  const HomeRef = useRef(null);
    const SportsRef = useRef(null);
    const contactRef = useRef(null);
    const items = [
      {label: (<span onClick={() => scrollToSection(HomeRef)} >Home</span>)},
      {label: (<span onClick={() => scrollToSection(SportsRef)} >Sports</span>)},
      {label: (<span onClick={() => scrollToSection(contactRef)} >Contact</span>)}
  ];
 

const scrollToSection = (elementRef) => {
  window.scrollTo({
    top: elementRef.current.offsetTop,
    behavior: "smooth",
  });
};
  return (
    <Layout>
    <Header
         style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: 'white'
        }}
      >
        <div className="demo-logo" />
        <Menu
          theme="light"
          mode="horizontal"
          defaultSelectedKeys={['1']}
          items={items}
        />
      </Header>
    <Content
        className="site-layout"
      >
    <Row ref={HomeRef} >
    <Col xs={24} sm={24} md= {24} style={{ backgroundImage: `url(${image})`, backgroundRepeat:'round', justifyContent:'center',alignItems: 'center', color:'white',textAlign: 'center', minHeight: '720px' }}>
    
    <Image
            style={{marginTop: 10}}
            width="15%"
            src={require('../assets/deya-sports-logo.png')}
        />
    <span style={{paddingTop: '20%', display: 'flex', fontWeight: 'bolder', fontSize: "6vw", justifyContent:'center',alignItems:'center'}}>DEYA Sports Village</span>
    <span style={{display: 'flex', fontWeight: 'bolder', fontSize: "4vw", justifyContent:'center',alignItems:'center'}}>Welcomes you</span>
        </Col>
    </Row>
    <Sports Sports={SportsRef} />
    <Contact Contact={contactRef} />
    </Content>
    
    </Layout>
   
  );
}

export default Home;
