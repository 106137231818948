import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import Home from "./Home";

const RoutesComponent = () => {
    
    return (
            <HashRouter>
                <Routes>
                    <Route path={"/"} element={<Home />} />
                </Routes>
            </HashRouter>
    );
};

export default RoutesComponent;
